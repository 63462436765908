import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useNavigate } from "react-router-dom"

export default function StickyHeadTable2({ colIds, labels, tableRowData, widths, pagination }) {
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState(tableRowData)

   const columns = [
      { id: colIds[0], label: labels[0], minWidth: widths[0] }
   ]

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   React.useEffect(() => {
      //console.log("colIds: ",  colIds, "\nlabels: ", labels, "\ntableRowData: ", tableRowData)
   }, [])

   const getProfilePage = (filter) => {
      if (filter === "Method") {
         let ax = tableRowData
         ax.sort(function(a,b) {
               return a['FunctionName'].localeCompare(b['FunctionName']);
        })
         
       // console.log("SORRTTTT",  ax)
        setRows(ax)
      }
      if (filter === "Usage") {
         tableRowData.sort(function(a,b) {
            return b['Usage']  - a['Usage']
        })
        // console.log("SORRTTTT2", tableRowData)
         setRows(tableRowData)
      }
      if (filter === "Year") {
         tableRowData.sort(function(a,b) {
            return b['Year']  - a['Year']
        })
        // console.log("SORRTTTT3", tableRowData)
         setRows(tableRowData)
      }
      setRows(tableRowData)
   }

   return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
         <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={column.id}
                           align={column.align}
                           style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                        >
                           {column.label === "Method" || column.label === "Usage" || column.label === "Year" ? <a onClick={() => getProfilePage(column.label)} href="#"> {column.label} </a>  : <> {column.label} </>}
                           
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>

               <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                     return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row[colIds[0]]}>
                           {columns.map((column) => {
                              const value = row
                              return (
                                 <TableCell key={column.id} align={column.align}>
                                 <a href={value}> {value} </a>
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </Table>
         </TableContainer>
         {pagination ? (
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         ) : (
            <></>
         )}
      </Paper>
   )
}
