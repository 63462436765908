import React, { useEffect, useState } from "react"
import Header from "../components/Header"
import "../App.css"
import StickyHeadTable from "../components/AuthorContributionTable"

function AuthorContribution() {
   const [authorContribution, setAuthorContribution] = useState(JSON.parse(window.localStorage.getItem("authorContribution")))
   const [funcName, setFuncName] = useState(window.localStorage.getItem("funcNameAuthor"))
   const [methodLink, setMethodLink] = useState(window.localStorage.getItem("methodLink"))
   const [funcSignature, setFuncSignaure] = useState(window.localStorage.getItem("funcSignature"))

   useEffect(() => {
    let usage = JSON.parse(window.localStorage.getItem("authorContribution"))
   // console.log("authorsss: " + usage)
    usage.sort(function(a,b) {
      return b['AuthorChanges']  - a['AuthorChanges']
   })
    setAuthorContribution(usage)
    setFuncName(window.localStorage.getItem("funcNameAuthor"))
    setMethodLink(window.localStorage.getItem("methodLink"))
    }, [])

   return (
      <div>
      <Header />
      <div style={{margin: "auto",width: "70%"}}>
        
         <h2 style={{ textAlign:"center"}}>Method Name: <a href={methodLink} target="_blank"> {funcName} </a> </h2>
         <h4>Method Signature: <h4 style={{ fontSize: "14px" , display:"inline", fontWeight:"normal",textDecoration: "none"}}>  {funcSignature}</h4></h4>  
         <div>
         <StickyHeadTable
                           colIds={["AuthorName", "AuthorEmail", "AuthorChanges"]}
                           labels={["Developer Name", "Developer Email", "Total Added/Deleted Lines By Developer"]}
                           widths={[60, 60, 70]}
                           pagination={true}
                           tableRowData={authorContribution}
                           name={"Author Contribution"}
                        />
         </div>
      </div>
      </div>
   )
}

export default AuthorContribution
