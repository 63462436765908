import React from "react"
import "./Header.css"
import searchIcon from "../assets/search2.svg.png"
function Navbar() {
   return (
      <div className="Navbar-header">
         <div style={{ alignItems: "center", textAlign: "center", fontSize: "25px" }}>
            DevScholar
         </div>
         {/* <img src={searchIcon} className="App-headerImage" alt="logo" /> */}
         {/* <div className="Navbar-links">
				<a href="/Analysis">Analysis</a>
				<a href="/Account">Account</a>
				<a href="/CreateTask">Create Task</a>
				<a href="/Payment">Payment</a>
			</div> */}
      </div>
   )
}

export default Navbar
