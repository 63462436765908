import "./App.css"
import Header from "./components/Header"
import Table from "./components/Table"
import Input from "./pages/Input"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Profile from "./pages/Profile"
import Usage from "./pages/Usages"
import AuthorContribution from "./pages/AuthorContribution"

function App() {
   return (
      <Router>
         <Routes>
            {/* <Route exact path="/" element={<Input />} /> */}
            <Route exact path="/" element={<Profile />} />
            <Route exact path="/Usage" element={<Usage />} />
            <Route exact path="/AuthorContribution" element={<AuthorContribution />} />
         </Routes>
      </Router>
   )
}

export default App
