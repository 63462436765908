import * as React from "react"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import NativeSelect from "@mui/material/NativeSelect"

export default function NativeSelectDemo({ profiles, curAuthor, setCurAuthor, label}) {
   return (
      <Box sx={{ minWidth: 120, maxWidth: 280, marginBottom: 2, marginLeft: 35 }}>
         <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
               {label}
            </InputLabel>
            <NativeSelect
               value={curAuthor}
               onChange={(e) => setCurAuthor(e.target.value)}
               style={{ fontWeight: "bold", fontSize: "18px" }}
               defaultValue={30}
               inputProps={{
                  name: "name",
                  id: "uncontrolled-native",
               }}
            >
               {profiles.map((id, row) => {
                  return (
                     <option key={row} value={id}>
                        {id}
                     </option>
                  )
               })}
            </NativeSelect>
         </FormControl>
      </Box>
   )
}
