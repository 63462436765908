import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useNavigate } from "react-router-dom"
import { Tooltip } from 'react-tooltip'

export default function TopAuthors({ colIds, labels, tableRowData, widths, pagination, setCurAuthor }) {
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState(tableRowData)
   const [keyCounter, setKeyCounter] = React.useState(Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1));
   const navigate = useNavigate()

   const columns = [
      { id: colIds[0], label: labels[0], minWidth: widths[0], align: "center" },
      { id: colIds[1], label: labels[1], minWidth: widths[1], align: "left" },
      { id: colIds[2], label: labels[2], minWidth: widths[2], align: "center" },
      {
         id: colIds[3],
         label: labels[3],
         minWidth: widths[3],
         align: "center",
         format: (value) => value.toLocaleString("en-US"),
      },
      { id: colIds[4], label: labels[4], minWidth: widths[4], align: "center" },
      { id: colIds[5], label: labels[5], minWidth: widths[5], align: "center" },
      { id: colIds[6], label: labels[6], minWidth: widths[6], align: "center" },
   ]

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   React.useEffect(() => {
     // console.log("colIds: ",  colIds, "\nlabels: ", labels, "\ntableRowData: ", tableRowData)
   }, [])

   React.useEffect(() => {
     // console.log("HELOOO")
      setRows(tableRowData)
   }, [tableRowData])

   const getProfilePage = (filter) => (event) => {
      event.preventDefault();
      setKeyCounter(Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1))
      if (filter === "Developer") {
         let ax = tableRowData
         ax.sort(function(a,b) {
               return a['author'].localeCompare(b['author']);
        })
       // console.log("SORRTTTT",  ax)
        setRows(ax)
        setPage(0)
      
      }

      if (filter === "LWDH-Index") {
         let ax = tableRowData
         ax.sort(function(a,b) {
               if (b['locwh'] !== a['locwh']) {
                  return b['locwh']  - a['locwh'];
               } else if (b['cwh'] !== a['cwh']) {
                  return b['cwh'] !== a['cwh']
               } else if (b['h'] !== a['h']) {
                  return b['h'] !== a['h']
               }
        })
       // console.log("SORRTTTT",  ax)
        setRows(ax)
        setPage(0)
      }

      if (filter === "CWDH-Index") {
         let ax = tableRowData
         ax.sort(function(a,b) {
            if (b['cwh'] !== a['cwh']) {
               return b['cwh']  - a['cwh'];
            } else if (b['locwh'] !== a['locwh']) {
               return b['locwh'] !== a['locwh']
            } else if (b['h'] !== a['h']) {
               return b['h'] !== a['h']
            }
        })
       // console.log("SORRTTTT",  ax)
        setRows(ax)
        setPage(0)
      }
      if (filter === "DH-Index") {
         let ax = tableRowData
         ax.sort(function(a,b) {
            if (b['h'] !== a['h']) {
               return b['h']  - a['h'];
            } else if (b['locwh'] !== a['locwh']) {
               return b['locwh'] !== a['locwh']
            } else if (b['cwh'] !== a['cwh']) {
               return b['cwh'] !== a['cwh']
            }
        })
       // console.log("SORRTTTT",  ax)
        setRows(ax)
        setPage(0)
      }
      
      if (filter === "Total CC") {
         tableRowData.sort(function(a,b) {
            return b['total_cc']  - a['total_cc']
        })
      //   console.log("SORRTTTT4", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
      if (filter === "Total Contributed LOC") {
         tableRowData.sort(function(a,b) {
            return b['total_loc']  - a['total_loc']
        })
      //   console.log("SORRTTTT5", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
   }

   return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
         <TableContainer key={keyCounter} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={column.id}
                           align={column.align}
                           style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                           className={`tablehead-${column.label}`}
                        >
                           {column.label === "Developer" || column.label === "LWDH-Index" || column.label === "CWDH-Index" || column.label === "DH-Index" || column.label === "Total CC" || column.label === "Total Contributed LOC" ? <a onClick={getProfilePage(column.label)} href="#"> {column.label} </a>  : <> {column.label} </>}
                           {column.label !== "Developer" && column.label !== "Total LOC" && column.label !== "Total CC" && column.label !== "DE-Index" && column.label !== "DG-Index" ? <Tooltip
                              anchorSelect={`.tablehead-${column.label}`}
                              content= {column.label === "LWDH-Index"? 'H-Index Calculated Using LwUsage': column.label === "CWDH-Index"? 'H-Index Calculated Using CwUsage' : column.label === "DH-Index"? 'Developer\'s H-Index' : 
                              column.label === "DG-Index"? 'Developer\'s G-Index' : column.label === "DE-Index"? 'Developer\'s E-Index' : `${column.label}`}
                           /> : <></>}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>

               <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                     row["ID"] = ((i + 1) + (rowsPerPage * (page)))
                     return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)}>
                           {columns.map((column) => {
                              const value = row[column.id]
                              return (
                                 <TableCell key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)} align={column.align}>
                                 {column.id === "author" ?  <a onClick={() => setCurAuthor(value)} href="#"> {value} </a> : <> {value} </>}
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </Table>
         </TableContainer>
         {pagination ? (
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         ) : (
            <></>
         )}
      </Paper>
   )
}
