import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"

export default function Coauthors({ coauthors, setCurAuthor }) {
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const columns = [{ id: "author", minWidth: 290 }]
   const rows = coauthors

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   React.useEffect(() => {
      //console.log("columns", columns)
   })
   return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
         <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
               <TableBody>
                  {coauthors['coAuthors'].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                   
                     return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row}>
                           {columns.map((column,id) => {
                             
                              const value = row
                              
                              return (
                                 <TableCell key={column.id} align={column.align}>
                                   {/* <a onClick={() => setUsageLinks(row.UsageLinks, row.FunctionName)} href="#"> {value} </a> */}
                                    <a onClick={(e) => setCurAuthor(value)} href="#"> {value} </a>
                                   {/*value*/}
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </Table>
         </TableContainer>
         <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={coauthors['coAuthors'].length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </Paper>
   )
}
