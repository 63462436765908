import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { Tooltip } from 'react-tooltip'
export default function MethodsTable({ colIds, labels, tableRowData, widths, pagination}) {
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState(tableRowData)
   const navigate = useNavigate()

   const columns = [
      { id: colIds[0], label: labels[0], minWidth: widths[0], align: "left" },
      { id: colIds[1], label: labels[1], minWidth: widths[1], align: "center" },
      {
         id: colIds[2],
         label: labels[2],
         minWidth: widths[2],
         align: "center",
         format: (value) => value.toLocaleString("en-US"),
      },
      { id: colIds[3], label: labels[3], minWidth: widths[3], align: "center" },
      { id: colIds[4], label: labels[4], minWidth: widths[4], align: "center" },
      { id: colIds[5], label: labels[5], minWidth: widths[5], align: "center" },
      { id: colIds[6], label: labels[6], minWidth: widths[6], align: "center" },
      { id: colIds[7], label: labels[7], minWidth: widths[7], align: "center" }
   ]

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   const setAuthorContributions = (funcName, authorContribution, methodLink, funcSignature) => {
     // console.log("set usage")
      window.localStorage.setItem("funcNameAuthor", funcName)
      window.localStorage.setItem("methodLink", methodLink)
      window.localStorage.setItem("authorContribution", JSON.stringify(authorContribution))
      window.localStorage.setItem("funcSignature", funcSignature)
      
   }

   React.useEffect(() => {
     // console.log("colIds: ",  colIds, "\nlabels: ", labels, "\ntableRowData: ", tableRowData)
   }, [])

   React.useEffect(() => {
    //  console.log("HELOOO")
      setRows(tableRowData)
      setPage(0)
   }, [tableRowData])

   const getProfilePage = (filter) => {
      if (filter === "Method") {
         let ax = tableRowData
         ax.sort(function(a,b) {
               return a['FunctionName'].localeCompare(b['FunctionName']);
        })
         
       // console.log("SORRTTTT",  ax)
        setRows(ax)
        setPage(0)
      
      }

      if (filter === "LWUsage") {
         tableRowData.sort(function(a,b) {
            return b['WeightedLOCUsage']  - a['WeightedLOCUsage']
        })
        // console.log("SORRTTTTWeighted", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }

      if (filter === "CWUsage") {
         tableRowData.sort(function(a,b) {
            return b['WeightedCCUsage']  - a['WeightedCCUsage']
        })
        // console.log("SORRTTTTWeighted", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
      if (filter === "Usage") {
         tableRowData.sort(function(a,b) {
            return b['Usage']  - a['Usage']
        })
        // console.log("SORRTTTT2", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
      if (filter === "Year") {
         tableRowData.sort(function(a,b) {
            return b['Year']  - a['Year']
        })
       //  console.log("SORRTTTT3", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
      // if (filter === "% Contributed LOC") {
      //    tableRowData.sort(function(a,b) {
      //       return b['PercentageContributedLOC']  - a['PercentageContributedLOC']
      //   })
      //   // console.log("SORRTTTT4", tableRowData)
      //    setRows(tableRowData)
      //    setPage(0)
      // }
      if (filter === "LOC") {
         tableRowData.sort(function(a,b) {
            return b['LOC']  - a['LOC']
        })
        // console.log("SORRTTTT4", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
      if (filter === "CC") {
         tableRowData.sort(function(a,b) {
            return b['CC']  - a['CC']
        })
        // console.log("SORRTTTT5", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
   }

   const getUsagePage = async (e) => {
      e.preventDefault()
      // bunch of API calls
      navigate("/Usage")
   }

   const setUsageLinks = (usages, funcName) => {
    //  console.log("set usage")
      window.localStorage.setItem("usageLinks", usages)
      window.localStorage.setItem("funcName", funcName)
      navigate("/Usage")
   }

   return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
         <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <>
                           <TableCell
                              key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)}
                              align={column.align}
                              style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                              className={`tablehead-${column.label}`}
                           >
                              {column.label === "Method" || column.label === "LWUsage" || column.label === "CWUsage" || column.label === "Usage" || column.label === "Year" || column.label === "LOC" || column.label === "CC"  ? <a onClick={() => getProfilePage(column.label)} href="#"> {column.label} </a>  : <> {column.label} </>}

                          {column.label !== "Method" && column.label !== "Year" ? <Tooltip
                              anchorSelect={`.tablehead-${column.label}`}
                              content= {column.label === "LWUsage"? '(Usage + 1) * Method\'s LOC * (Developer\'s LOC Contribution / Total LOC Contributions)': column.label === "CWUsage"? '(Usage + 1) * CC' : column.label === "Usage"? 'Number of Times Method Was Referenced In The Project' : 
                              column.label === "CC"? 'Method\'s Cyclomatic Complexity' : column.label === "LOC"? 'Lines of Code' : column.label === "% Contributed LOC"? 'Developer\'s LOC Contribution / Total LOC Contributions' : `${column.label}`}
                           /> : <></>}
                           </TableCell>
                        </>
                     ))}
                  </TableRow>
               </TableHead>

               <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                     {
                        console.log("row", row.FunctionName)
                     }
                     return (
                        <TableRow hover role="checkbox" tabIndex={-1}  key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)}>
                           {columns.map((column) => {
                              const value = row[column.id]
                              return (
                                
                                 <TableCell key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)} align={column.align}>
                                 {column.id === "FunctionName" ? <Link to="/AuthorContribution" onClick={() => setAuthorContributions(row.FunctionName, row.AuthorsData, row.Link, row.FunctionSignature)} > {value} </Link> : <> {value} </>}
                                 </TableCell>
                                
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </Table>
         </TableContainer>
         {pagination ? (
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         ) : (
            <></>
         )}
      </Paper>
   )
}
