import "./Profile.css"
import Header from "../components/Header"
import StickyHeadTable from "../components/Table"
import React, { useState, useEffect } from "react"
import axios from "axios"
import Coauthors from "../components/Coauthors"
import TopAuthors from "../components/TopAuthors"
import BarChart from "../components/BarChart"
import Dropdown from "../components/Dropdown"
import apolloData from "./apollo-result.json"
import springData from "./spring-result.json"
import retrofitData from "./retrofit-result.json"
import dubboData from "./dubbo-result.json"
import MethodsTable from "../components/MethodsTable"



function Profile() {
   const [result, setResult] = useState(null)
   const [indexData, setIndexData] = useState(null)
   const [functnTable, setFunctnTable] = useState(null)
   const [functnTableAuthor, setFunctnTableAuthor] = useState(null)
   const [curAuthor, setCurAuthor] = useState("")
   const [curProject, setCurProject] = useState("Apollo")
   const [allAuthors, setAllAuthors] = useState("")
   const [githubLink, setGithubLink] = useState("")
   const [path, setPath] = useState("")
   const [langType, setLangType] = useState("")
   const [inputs, setInputs] = useState("")

   const [authorIndices, setAuthorIndices] = useState(null)
   const [coAuthorsAll, setCoAuthorsAll] = useState("")
   const [coAuthors, setCoAuthors] = useState(null)

   const [associatedNamesAll, setAssociatedNamesAll] = useState("")
   const [associatedNames, setAssociatedNames] = useState(null)

   const [resultBackend, setResultBackend] = useState(apolloData);

   // const [authorYearsAll, setAuthorYearsAll] = useState(null)
   // const [authorYears, setAuthorYears] = useState(null)

   const[labels, setLabels] = useState(null)
   const[values, setValues] = useState(null)
   const[totalValues, setTotalValues] = useState(null)

   const [ready, setReady] = useState(false)

   const projects = ["Spring Boot", "Apollo", "Retrofit", "Dubbo"]

   const message = async () => {
      try {
         // let res = await axios.get("http://127.0.0.1:8000/", {
         //    params: {githubLink, path, langType}
         // });
         // let resultBackend = res.data
      
         // console.log("result", resultBackend)
         //window.localStorage.setItem("resultBackend",  JSON.stringify( resultBackend ) )
         setResult(resultBackend["authorIndex"])
         setFunctnTable(resultBackend["functionTable"])
         setAllAuthors(resultBackend["authors"].sort())
         setCurAuthor(resultBackend["authors"][0])
         setCoAuthorsAll(resultBackend["coAuthors"])
         setAssociatedNamesAll(resultBackend["associatedNames"])
         // setAuthorYearsAll(resultBackend["authorYears"])
         setAuthorIndices(resultBackend["authorsIndices"])
        // console.log("author in profiles: ", allAuthors)
        
      } catch (e) {
      //   console.log(e)
      }
   }

   const message2 = async () => {
      let resultBackend = JSON.parse( window.localStorage.getItem("resultBackend") ); 
      try {
        // console.log("result", resultBackend)
         if (resultBackend !== null) {
            setResult(resultBackend["authorIndex"])
            setFunctnTable(resultBackend["functionTable"])
            setAllAuthors(resultBackend["authors"])
            setCurAuthor(resultBackend["authors"][0])
            setCoAuthorsAll(resultBackend["coAuthors"])
            setAssociatedNamesAll(resultBackend["associatedNames"])
            // setAuthorYearsAll(resultBackend["authorYears"])
            setAuthorIndices(resultBackend["authorsIndices"])
          //  console.log("author in profiles: ", allAuthors)
        }
        
      } catch (e) {
        // console.log(e)
      }
   }

   useEffect(() => {
      if(coAuthors !== null && associatedNames !== null && functnTableAuthor != null && indexData != null && authorIndices != null) {
         setReady(true)
      }
      else{
         setReady(false)
      }

   }, [coAuthors, associatedNames, functnTableAuthor, indexData, authorIndices])

   useEffect(() => {
      if(coAuthorsAll !== null) {
         for (var i = 0; i < coAuthorsAll.length; ++i) {
          //  console.log("author name in result co: ", curAuthor)
            if (coAuthorsAll[i].author === curAuthor) {
           //    console.log("in if")
           //    console.log("coAuthor in if", coAuthorsAll[i])
               setCoAuthors(coAuthorsAll[i])
               break;
            }
         }
      }

   }, [coAuthorsAll, curAuthor])

   useEffect(() => {
      if(associatedNamesAll !== null) {
         for (var i = 0; i < associatedNamesAll.length; ++i) {
            
           // console.log("author name in email aasso: ", associatedNamesAll[i].email === curAuthor, "cur", curAuthor, "aa", associatedNamesAll[i].email)
            const email = (associatedNamesAll[i].email).replace(/\s/g, "");
            const email2 = email.replace(/,/g, '');
            if (email2 === curAuthor) {
             //  console.log("in if associated")
             //  console.log("associated in if", associatedNamesAll[i])
               setAssociatedNames(associatedNamesAll[i])
               break;
            }
         }
      }

   }, [associatedNamesAll, curAuthor])

   // useEffect(() => {
   //    if(authorYearsAll !== null) {
   //       for (var i = 0; i < authorYearsAll.length; i++) {
   //          if (authorYearsAll[i].author === curAuthor) {
   //             console.log("cur author is: " + Object.values(authorYearsAll[i]['years']))
   //             setLabels(Object.keys(authorYearsAll[i]['years']))
   //             setValues(Object.values(authorYearsAll[i]['years']))
   //             setTotalValues(Object.values(authorYearsAll[i]['years']).reduce((a, b) => a + b, 0))
   //             setAuthorYears(authorYearsAll[i])
   //             break;
   //          }
   //       }
   //    }

   // }, [authorYearsAll, curAuthor])

   useEffect(() => {
      if (curProject === "Spring Boot") {
         setResultBackend(springData)
      } else if (curProject === "Apollo") {
         setResultBackend(apolloData)
      } else if (curProject === "Retrofit") {
         setResultBackend(retrofitData)
      } else {
         setResultBackend(dubboData)
      }
   }, [curProject])

   useEffect(() => {
      if(githubLink === "" || githubLink === null ||
      path === "" || path === null ||
      langType === "" || langType === null
      ) {
         setInputs(false)
      }

      if (githubLink !== null && githubLink !== "" && 
      path !== null && path !== "" && langType !== null && langType !== ""){
         setInputs(true)
      }

   },[githubLink, path, langType])

   useEffect(() => {
      if (result !== null) {
         for (var i = 0; i < result.length; ++i) {
          //  console.log("author name in result: ", curAuthor)
            if (result[i].name === curAuthor) {
            //   console.log("in if")
            //   console.log(result[i].data)
               setIndexData(result[i].data)
               break;
            }
         }
      }
   }, [result, curAuthor])

   useEffect(() => {
      if(functnTable !== null) {
         for (var i = 0; i < functnTable.length; ++i) {
         //   console.log("author name in result: ", curAuthor)
            if (functnTable[i].author === curAuthor) {
           //    console.log("in if")
           //    console.log(functnTable[i].data)
               let tableRowData = functnTable[i].data
               tableRowData.sort(function(a,b) {
                  return b['WeightedLOCUsage']  - a['WeightedLOCUsage']
               })
               setFunctnTableAuthor(tableRowData)
               break;
            }
         }
      }

   }, [curAuthor])

   useEffect(() => {
      message()
   }, [resultBackend])

   useEffect(() => {
    //  console.log("index data: ", indexData)
   }, [indexData, resultBackend])


   return (
      <div>
      {ready === true ?
      <div className="App">
         <Header />
         <div className="App__DivideIntoTwo">
            <div className="App__LeftSection">
               <div className="App__ProfileDesc">
                  <div className="App__DescExceptPic">

                  <div>
                     {allAuthors !== "" && allAuthors !== null ? <Dropdown
                        profiles={allAuthors}
                        curAuthor={curAuthor}
                        setCurAuthor={setCurAuthor}
                        label= {"Developer"}
                     /> : <></>
}
                     <Dropdown
                        profiles={projects}
                        curAuthor={curProject}
                        setCurAuthor={setCurProject}
                        label = {"Project"}
                     />
                     </div>
                     <h6 style={{ fontSize:"16px", marginBottom:"5px", fontWeight:"bold", display:"inline" }}>Associated Names and Emails: 
                     {associatedNames !== "" && associatedNames !== null ? <h6 style={{ fontSize: "14px" , display:"inline", textDecoration: "none"}}> {JSON.stringify(associatedNames['Associated Names'])} </h6> : "null"}
                     </h6>
                     {/* <h6 style={{ fontSize:"16px", marginBottom:"5px", fontWeight:"bold", display:"inline" }}>First Year of Contribution: 
                     {labels !== null ? <h6 style={{ fontSize: "14px" , display:"inline", textDecoration: "none"}}> {labels[0]} </h6> : "null"} */}
                     {/* </h6> */}
                     <h6 style={{ fontSize:"16px", marginBottom:"5px", fontWeight:"bold", display:"inline" }}>Total Number of Methods Contributed: 
                     {functnTableAuthor.length !== null ? <h6 style={{ fontSize: "14px" , display:"inline", textDecoration: "none"}}> {functnTableAuthor.length} </h6> : "null"}
                     </h6>
                  </div>
               </div>
               <div className="App__Table">
                  <div className="App__TableTitles">
                     {functnTableAuthor !== null ? (
                        <MethodsTable
                           colIds={["FunctionName", "WeightedLOCUsage", "WeightedCCUsage","Usage", "CC", "LOC", "PercentageContributedLOC", "Year"]}
                           labels={["Method", "LWUsage", "CWUsage", "Usage", "CC", "LOC", "% Contributed LOC", "Year"]}
                           widths={[165, 40, 40, 40, 40, 40, 40, 40]}
                           pagination={true}
                           tableRowData={functnTableAuthor}
                           name={"Methods Data"}
                        />
                     ) : (
                        <> Nothing to show!</>
                     )}
                  </div>
                  <div className="App__TableResults"></div>
               <div className="App__RightSectionTitle">
                  <h4 style={{ fontWeight: "bold" }} >Top Developers</h4>
               </div>
               <div className="App__TableTitles">
                     {authorIndices !== null ? (
                        <TopAuthors className="TopAuthors"
                           colIds={["ID", "author", "locwh", "cwh", "h", "total_cc", "total_loc"]}
                           labels={["#", "Developer", "LWDH-Index", "CWDH-Index", "DH-Index", "Total CC", "Total Contributed LOC"]}
                           widths={[20, 72, 85, 85, 72, 72, 72]}
                           pagination={true}
                           tableRowData={authorIndices}
                           curAuthor={curAuthor}
                           setCurAuthor={setCurAuthor}
                        />
                     ) : (
                        <> Nothing to show!</>
                     )}
                  </div>
               </div>
            </div>
            <div className="App__RightSection">
               <div className="App__RightSectionTitle">
                  <div title="Author's impact anlaysis represented by the indices below"><h4 style={{ fontWeight: "bold" }}>Developer Metrics</h4></div>
               </div>
               <div className="App__RightSectionTable">
                  {indexData !== null ? (
                     <StickyHeadTable
                        colIds={["x", "All"]}
                        labels={["Metric", "Value"]}
                        widths={[150, 150]}
                        pagination={false}
                        tableRowData={indexData}
                        name={"Reused by"}
                     />
                  ) : (
                     <>Nothing to show!</>
                  )}
               </div>
               {/* <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                  {labels!== null ? <BarChart labels={labels} values={values} min={0} max={80} /> : <>Nothing</>}
               </div> */}
               <div className="App__RightSectionTitle">
                  <h4 style={{ fontWeight: "bold" }}>Co-developers</h4>
                  <div className="App__RightSectionTable">
                     {coAuthors !== null && coAuthors['coAuthors'].length > 0 ? <Coauthors coauthors={coAuthors} setCurAuthor={setCurAuthor} /> : <>Nothing to show!</>}
                  </div>
               </div>
            </div>
         </div>
      </div> : <div style={{ fontSize: "50px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} >Loading... </div>}
      </div>
   )
}

export default Profile