import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import "../App.css"
import StickyHeadTable2 from "../components/List"

function Usage() {
   const [usageLinks, setUsageLinks] = useState(window.localStorage.getItem("usageLinks").split(","))
   const [funcName, setFuncName] = useState(window.localStorage.getItem("funcName"))

   useEffect(() => {
    let usage = window.localStorage.getItem("usageLinks")
    //console.log(usage)
    setUsageLinks(usage.split(","))
    setFuncName(window.localStorage.getItem("funcName"))
    }, [])

   return (
      <div>
         <Header />
         <h2 style={{ textAlign:"center"}}>Usages: {funcName}</h2>
         <div>
         <StickyHeadTable2
                           colIds={["Function Usage"]}
                           labels={["Links"]}
                           widths={[470]}
                           pagination={true}
                           tableRowData={usageLinks}
                        />
         </div>
      </div>
   )
}

export default Usage
