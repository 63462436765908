import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useNavigate } from "react-router-dom"

export default function StickyHeadTable({ colIds, labels, tableRowData, widths, pagination, name }) {
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState(tableRowData)
   const navigate = useNavigate()

   const columns = [
      { id: colIds[0], label: labels[0], minWidth: widths[0], align: "center" },
      { id: colIds[1], label: labels[1], minWidth: widths[1], align: "center" },
   ]

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   React.useEffect(() => {
     // console.log("colIds: ",  colIds, "\nlabels: ", labels, "\ntableRowData: ", tableRowData)
   }, [])

   React.useEffect(() => {
   //   console.log("HELOOO")
      setRows(tableRowData)
      setPage(0)
   }, [tableRowData])

   const getProfilePage = (filter) => {
      if (filter === "Method") {
         let ax = tableRowData
         ax.sort(function(a,b) {
               return a['FunctionName'].localeCompare(b['FunctionName']);
        })
         
      //  console.log("SORRTTTT",  ax)
        setRows(ax)
        setPage(0)
      }
      if (filter === "Usage") {
         tableRowData.sort(function(a,b) {
            return b['Usage']  - a['Usage']
        })
      //   console.log("SORRTTTT2", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
      if (filter === "Year") {
         tableRowData.sort(function(a,b) {
            return b['Year']  - a['Year']
        })
      //   console.log("SORRTTTT3", tableRowData)
         setRows(tableRowData)
         setPage(0)
      }
   }

   const getUsagePage = async (e) => {
      e.preventDefault()
      // bunch of API calls
      navigate("/Usage")
   }

   const setUsageLinks = (usages, funcName, authorContribution) => {
    //  console.log("set usage")
      window.localStorage.setItem("usageLinks", usages)
      window.localStorage.setItem("funcName", funcName)
      window.localStorage.setItem("authorContribution", authorContribution)
      navigate("/Usage")
   }

   const setAuthorContributions = (funcName, authorContribution, methodLink) => {
    //  console.log("set usage")
      window.localStorage.setItem("funcNameAuthor", funcName)
      window.localStorage.setItem("methodLink", methodLink)
      window.localStorage.setItem("authorContribution", JSON.stringify(authorContribution))
      navigate("/AuthorContribution")
   }

   return (
      <Paper sx={{ width: "100%", overflow: "hidden"}}>
         <TableContainer sx={{ maxHeight: 480} }>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {columns.map((column) => (
                        <TableCell
                           key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)}
                           align={column.align}
                           style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                        >
                           {
                              column.label === "Total Lines Contributed to" ? <div title="Total number of lines contributed (added/deleted) to this method by the author">{column.label}</div> 
                              : 
                              column.label === "Usage" ? <div title="Number of references of the method in the project"> <a onClick={() => getProfilePage(column.label)} href="#"> {column.label} </a>  </div>
                              :
                              column.label === "Year" ? <div title="The first time (year) contribution made to this method "> <a onClick={() => getProfilePage(column.label)} href="#"> {column.label} </a>  </div>
                              :
                              column.label === "Method" ? <a onClick={() => getProfilePage(column.label)} href="#"> {column.label} </a>  
                              : <> {column.label} </>
                           }
                           
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>

               <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    
                     return (
                        row["x"] == "DG-Index" || row["x"] == "DE-Index" ?  <></> : 
                        <TableRow hover role="checkbox" tabIndex={-1} key={Math.floor((Math.random() * 1000000) + 1)}>
                           {columns.map((column) => {
                              console.log("column id is ", column.id)
                              const value = row[column.id]
                              return (
                                 <TableCell key={Math.floor((Math.random() * 1000000 * Math.pow(2,256)) + 1)} align={column.align}>
                                 {column.id === "FunctionName" ? <a href="#" onClick={() => setAuthorContributions(row.FunctionName, row.Authors_Data, row.Link)} > {value} </a> : <> {value} </>}
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </Table>
         </TableContainer>
         {pagination ? (
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         ) : (
            <></>
         )}
      </Paper>
   )
}
